$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);


@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t#{$size},
    .#{$abbrev}y#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r#{$size},
    .#{$abbrev}x#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b#{$size},
    .#{$abbrev}y#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l#{$size},
    .#{$abbrev}x#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

// Negative margins (e.g.; where `.mb-n1` is negative version of `.mb-1`)
@each $size, $length in $spacers {
  @if $size != 0 {
    .mn#{$size} {
      margin: -$length !important;
    }
    .mtn#{$size},
    .myn#{$size} {
      margin-top: -$length !important;
    }
    .mrn#{$size},
    .mxn#{$size} {
      margin-right: -$length !important;
    }
    .mbn#{$size},
    .myn#{$size} {
      margin-bottom: -$length !important;
    }
    .mln#{$size},
    .mxn#{$size} {
      margin-left: -$length !important;
    }
  }
}

// Some special margin utils
.mAuto {
  margin: auto !important;
}

.mtAuto,
.myAuto {
  margin-top: auto !important;
}

.mrAuto,
.mxAuto {
  margin-right: auto !important;
}

.mbAuto,
.myAuto {
  margin-bottom: auto !important;
}

.mlAuto,
.mxAuto {
  margin-left: auto !important;
}

// Columns
@for $i from 1 through 12 {
  .col#{$i} {
    width: $i / 12 * 100%;
    display: inline-block;
  }
}

.row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.col {
  padding-left: 1rem;
  padding-right: 1rem;
}

.colAuto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; // Reset earlier grid tiers
}

.dFlex {
  display: flex;
}

.flexRow {
  flex-direction: row !important;
}

.flexColumn {
  flex-direction: column !important;
}

.flexRowReverse {
  flex-direction: row-reverse !important;
}

.flexColumnReverse {
  flex-direction: column-reverse !important;
}

.flexWrap {
  flex-wrap: wrap !important;
}

.flexNoWrap {
  flex-wrap: nowrap !important;
}

.flexWrapReverse {
  flex-wrap: wrap-reverse !important;
}

.justifyContentStart {
  justify-content: flex-start !important;
}

.justifyContentEnd {
  justify-content: flex-end !important;
}

.justifyContentCenter {
  justify-content: center !important;
}

.justifyContentBetween {
  justify-content: space-between !important;
}

.justifyContentAround {
  justify-content: space-around !important;
}

.alignItemsStart {
  align-items: flex-start !important;
}

.alignItemsEnd {
  align-items: flex-end !important;
}

.alignItemsCenter {
  align-items: center !important;
}

.alignItemsBaseline {
  align-items: baseline !important;
}

.alignItemsStretch {
  align-items: stretch !important;
}

.alignContentStart {
  align-content: flex-start !important;
}

.alignContentEnd {
  align-content: flex-end !important;
}

.alignContentCenter {
  align-content: center !important;
}

.alignContentBetween {
  align-content: space-between !important;
}

.alignContentAround {
  align-content: space-around !important;
}

.alignContentStretch {
  align-content: stretch !important;
}

.alignSelfAuto {
  align-self: auto !important;
}

.alignSelfStart {
  align-self: flex-start !important;
}

.alignSelfEnd {
  align-self: flex-end !important;
}

.alignSelfCenter {
  align-self: center !important;
}

.alignSelfBaseline {
  align-self: baseline !important;
}

.flexGrow1 {
  flex-grow: 1;
}

.width900 {
  width: 900px;
}

.alignFormTop {
  padding-top: 2.2rem;
}

.bold {
  font-weight: 500;
}

.sideBar {
  height: 100%;
  width: 250px;
  min-width: 250px;
  overflow: auto;
  box-shadow: #302f2f 1px 0 4px 0;

  .menuItem {
    font-weight: 500;
    cursor: pointer;
    font-size: 20px;

    &:hover,&.menuActive {
      background-color: #b2dfdb;
    }
  }
}

.entityListResult {
  display: flex;
  flex-wrap: wrap;

  a {
    background-color: #fff;
    width: 33%;
    border: 1px solid #e0e0e0;

    :hover {
      background-color: #ddd;
    }

    div {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
}

label {
  color: #000 !important;
  font-size: 14px !important;
}

input, textarea, select {
  box-sizing: border-box !important;
  background-color: white !important;
  padding: 0 10px !important;
  border: 1px solid black !important;
  border-radius: 5px !important;

  &:focus {
    outline: 1px solid transparent !important;
    border-color: #009688 !important;
    border-width: 2px !important;
    box-shadow: none !important;
  }
}

select {
  margin-bottom: 1rem !important;
}

textarea {
  padding: 10px !important;
}

.navBox {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

table {
  &.noTableBorder {
    tbody {
      tr {
        border: none !important;
      }
    }
  }
}

.imageEdit {
  .removeImageButton {
    opacity: 0;
  }

  &:hover .removeImageButton {
    opacity: 1;
  }
}

.removeImageButton {
  transition: opacity 0.8s;
  cursor: pointer;
  &:hover {
    filter: brightness(50%);
  }
}