.crop-container {
  position: relative;
  width: 420px;
  height: 420px;
  background: #333;
}

.controls {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.reactEasyCrop_CropAreaGrid::before {
  border-left: 1px solid rgba(255, 9, 9, 0.7) !important;
  border-right: 1px solid rgba(255, 9, 9, 0.7) !important;
  left: 7% !important;
  right: 7% !important;
}

.reactEasyCrop_CropAreaGrid::after {
  border-top: 1px solid rgba(255, 9, 9, 0.7) !important;
  border-bottom: 1px solid rgba(255, 9, 9, 0.7) !important;
  top: 7% !important;
  bottom: 7% !important;
}

.upload-modal {
  height: fit-content !important;
  max-height: 100% !important;
}

.color-input {
  margin-right: 10px;
  padding: 0 !important;
  border: 1px solid black !important;
  height: 30px;
  width: 30px;
}